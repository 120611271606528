import React, { Suspense } from 'react'
import { styled } from '@mui/material/styles'
import { Helmet } from 'react-helmet-async'

import { useCellarContext } from '../../contexts'
import Navbar from '../Navbar/Navbar'
import SideMenu from '../SideMenu/SideMenu'

const PREFIX = 'Main'

const classes = {
  main: `${PREFIX}-main`,
  fab: `${PREFIX}-fab`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.main}`]: {
    position: 'relative',
    minHeight: 'calc(100vh - 64px)',
    width: '100%',
  },
  [`& .${classes.fab}`]: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const Main = ({ children }: React.PropsWithChildren<any>) => {
  const { config, club } = useCellarContext()
  const titleParts = []

  if (config?.company) {
    titleParts.push(config.company)
  }
  if (club) {
    titleParts.push(club.title)
  }
  const title = titleParts.join(' - ')

  return (
    <Root>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Navbar />
      <SideMenu />
      <div className={classes.main}>
        <Suspense fallback={<></>}>{children}</Suspense>
      </div>
    </Root>
  )
}

export default Main
