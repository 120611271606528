import { IAppParams } from '../types'

interface Interpolate {
  (data: {
    path: string
    params: IAppParams
    query?: any
    separator?: string
    baseUrl: string
  }): URL
}

export const interpolate: Interpolate = ({
  path,
  params,
  query,
  separator = '/',
  baseUrl,
}) => {
  const paramKeys = path.split(separator).reduce((acc: string[], part: string) => {
    if (part[0] === ':') {
      return [...acc, part.slice(1)]
    }
    return acc
  }, [])

  const interpolated = paramKeys.reduce((acc: string, key: string) => {
    return acc.replace(`:${key}`, params[key] || '')
  }, path)

  const url = new URL(`${baseUrl}${interpolated}`)
  if (query) {
    url.search = new URLSearchParams(query).toString()
  }

  return url
}
