import React, { createContext, useState, useEffect, useCallback } from 'react'

import { IAppParams } from '../../types'
import * as api from '../../api'

type Props = {
  params: IAppParams
}

type ContextProps = {
  publicExpense: any
  publicExpenseCount: number
}

export const PublicContext = createContext<ContextProps>({} as ContextProps)
const { Provider } = PublicContext

export const PublicProvider = ({ params, children }: React.PropsWithChildren<Props>) => {
  const [publicExpense, setPublicExpense] = useState<any>()
  const [publicExpenseCount, setPublicExpenseCount] = useState(0)

  const readExpensePublic = useCallback(async () => {
    const response = await api.readExpensePublic({ params })
    setPublicExpense(response.data?.expense)
    setPublicExpenseCount(response.data?.count)
  }, [params])

  useEffect(() => {
    if (params.expensePublicId) {
      readExpensePublic()
    }
  }, [params.expensePublicId, readExpensePublic])

  console.debug(params)

  return <Provider value={{ publicExpense, publicExpenseCount }}>{children}</Provider>
}
