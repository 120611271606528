import { createTheme, adaptV4Theme, Theme } from '@mui/material/styles'
import '@mui/styles'

import { getCustomStyle, getCustomTheme } from './api'

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const getTheme = async () => {
  const [customTheme, style] = await Promise.all([getCustomTheme({}), getCustomStyle({})])

  const baseTheme = {
    palette: {
      mode: 'light',
      primary: {
        main: '#623E6A',
      },
    },
    typography: {
      fontFamily: 'Quicksand',
    },
    overrides: {
      MuiAppBar: {
        root: {
          zIndex: 100,
        },
      },
    },
    components: {
      MuiFilledInput: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiListItem: {
        defaultProps: {
          dense: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
      },
      MuiFab: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
        },
      },
    },
  }
  const theme = createTheme(adaptV4Theme({ ...baseTheme, ...customTheme }))

  return {
    theme,
    style,
  }
}
