import React from 'react'
import { styled } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'

import { IAppParams } from '../../../types'

const PREFIX = 'PersonButton'

const classes = {
  link: `${PREFIX}-link`,
  iconButtom: `${PREFIX}-iconButtom`,
}

const StyledLink = styled(Link)(({ theme }) => ({
  [`&.${classes.link}`]: {
    marginRight: theme.spacing(3),
  },
  [`& .${classes.iconButtom}`]: {
    color: '#fff',
  },
}))

const PersonButton = () => {
  const params: IAppParams = useParams()
  const { lang } = params
  const profilePath = `/${lang}/profile`

  return (
    <StyledLink to={profilePath} id="app-bar__person-button" className={classes.link}>
      <IconButton className={classes.iconButtom} size="large">
        <PersonIcon />
      </IconButton>
    </StyledLink>
  )
}

export default PersonButton
