import React, { createContext, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'

type ContextProps = {
  drawerOpen: boolean
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  showCart?: boolean
  setShowCart: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppContext = createContext<ContextProps>({} as ContextProps)
const { Provider } = AppContext

export const AppProvider = ({
  children,
}: React.PropsWithChildren<Record<never, never>>) => {
  const [showCart, setShowCart] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <HelmetProvider>
      <Provider value={{ drawerOpen, setDrawerOpen, showCart, setShowCart }}>
        {children}
      </Provider>
    </HelmetProvider>
  )
}
