import React, { createContext, ReactNode, useState, useEffect } from 'react'

import * as api from '../../api'
import { IAppParams } from '../../types'

type ContextProps = {
  shop?: any
  setShop: React.Dispatch<React.SetStateAction<any>>
}

type IProps = {
  children: ReactNode
  params: IAppParams
}

export const ShopContext = createContext<ContextProps>({} as ContextProps)
const { Provider } = ShopContext

export const ShopProvider = ({ children, params }: IProps) => {
  const [shop, setShop] = useState<any>()
  const { lang, shopId } = params

  useEffect(() => {
    api
      .readShop({ params, query: { expand: true } })
      .then(response => {
        setShop(response.data)
      })
      .catch(err => {
        console.error(err)
      })
  }, [lang, shopId]) // eslint-disable-line

  return (
    <Provider
      value={{
        shop,
        setShop,
      }}
    >
      {children}
    </Provider>
  )
}
