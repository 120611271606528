import React, { ReactNode, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { LanguageProvider } from '../../contexts/LanguageContext/LanguageContext'
import { CellarProvider } from '../../contexts/CellarContext/CellarContext'
import useAuthRequestInterceptor from '../../hooks/useAuthRequestInterceptor/useAuthRequestInterceptor'
import useOnLanguageChange from './useOnLanguageChange/useOnLanguageChange'

import AuthRequiredDialog from '../../components/AuthRequiredDialog/AuthRequiredDialog'

interface ILanguageProps {
  children: ReactNode
}

const csBaseConfig = {
  enableCcpa: true,
  countryDetection: true,
  perPurposeConsent: true,
  ccpaAcknowledgeOnDisplay: true,
  whitelabel: false,
  lang: 'it',
  siteId: 2188007,
  cookiePolicyId: 91550873,
  banner: {
    acceptButtonDisplay: true,
    customizeButtonDisplay: true,
    acceptButtonColor: '#0073CE',
    acceptButtonCaptionColor: 'white',
    customizeButtonColor: '#DADADA',
    customizeButtonCaptionColor: '#4D4D4D',
    position: 'float-top-center',
    textColor: 'black',
    backgroundColor: 'white',
  },
}

const csConfigurations = {
  it: { ...csBaseConfig, lang: 'it', cookiePolicyId: 91550873 },
  en: { ...csBaseConfig, lang: 'en', cookiePolicyId: 88795903 },
}

const Language = ({ children }: ILanguageProps) => {
  const params: any = useParams()
  const history = useHistory()
  const [authIntercepted, setAuthIntercepted] = useState(false)
  const onLanguageChange = useOnLanguageChange(params)
  useAuthRequestInterceptor(setAuthIntercepted)

  const iubendCookieSolution = useMemo(() => {
    return (
      <Helmet>
        <script type="text/javascript">{`
          var _iub = _iub || []
          _iub.csConfiguration = ${JSON.stringify(
            csConfigurations[params.lang as 'it' | 'en']
          )}
        `}</script>
        <script
          type="text/javascript"
          src="https://cdn.iubenda.com/cs/ccpa/stub.js"
        ></script>
        <script
          type="text/javascript"
          src="https://cdn.iubenda.com/cs/iubenda_cs.js"
          async
        ></script>
      </Helmet>
    )
  }, [params.lang])

  return (
    <LanguageProvider params={params} onLanguageChange={onLanguageChange}>
      {iubendCookieSolution}
      <CellarProvider params={params}>
        <AuthRequiredDialog
          params={params}
          open={authIntercepted}
          onClose={goBack => {
            setAuthIntercepted(false)
            if (goBack) {
              history.goBack()
            }
          }}
        />
        {children}
      </CellarProvider>
    </LanguageProvider>
  )
}

export default Language
