import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import { AppProvider } from './contexts/AppContext/AppContext'
import { UserProvider } from './contexts/UserContext/UserContext'

import { getTheme } from './theme'
import * as routes from './routes'
import Main from './components/Main/Main'

const cache = createCache({
  key: 'css',
  prepend: true,
})

// This routes removes the old :cellarName param from the URL and recurses until it reaches /:lang if no matching route is found
const Redirector = (props: any) => {
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [nothing, lang, remove, ...rest] = props.match.url.split('/')
    const newUrl = [nothing, lang, ...rest].join('/')
    props.history.replace(newUrl)
  }, [props.history, props.match.url])

  return null
}

const Outer = () => {
  const [theme, setTheme] = useState<Theme | null>(null)

  useEffect(() => {
    const run = async () => {
      const { theme, style } = await getTheme()

      const styleElement = document.createElement('style')
      styleElement.innerText = style
      document.body.appendChild(styleElement)
      setTheme(theme)
    }
    run()
  }, [])

  if (!theme) {
    return null
  }

  return (
    <React.StrictMode>
      <CacheProvider value={cache}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppProvider>
              <UserProvider>
                <BrowserRouter>
                  <CssBaseline />
                  <Inner />
                </BrowserRouter>
              </UserProvider>
            </AppProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </React.StrictMode>
  )
}

const Inner = () => {
  return (
    <>
      <Switch>
        <Route path="/:lang/public/expenses/:expensePublicId" exact>
          <routes.Public>
            <routes.PublicExpensePage />
          </routes.Public>
        </Route>
        <Route path="/:lang">
          <routes.Language>
            <Main>
              <Switch>
                <Route path="/:lang" exact component={routes.CellarPage} />
                <Route path="/:lang/signup" exact component={routes.SignupPage} />
                <Route
                  path="/:lang/signup/:requestToken"
                  exact
                  component={routes.SignupPage}
                />
                <Route path="/:lang/login" exact component={routes.LoginPage} />
                <Route path="/:lang/profile">
                  <routes.Profile>
                    <routes.ProfilePage />
                  </routes.Profile>
                </Route>
                <Route path="/:lang/shop-pages/:shopPageId">
                  <routes.Shops>
                    <Route
                      path="/:lang/shop-pages/:shopPageId"
                      exact
                      component={routes.ShopsPage}
                    />
                    <Route
                      path="/:lang/shop-pages/:shopPageId/shops/:shopId"
                      exact
                      component={routes.ShopsPage}
                    />
                    <Route path="/:lang/shop-pages/:shopPageId/checkout">
                      <routes.Checkout>
                        <routes.CheckoutPage />
                      </routes.Checkout>
                    </Route>
                    <Route path="/:lang/shop-pages/:shopPageId/checkout-complete">
                      <routes.CheckoutCompletePage />
                    </Route>
                  </routes.Shops>
                </Route>
                <Route
                  path="/:lang/password/confirm/:requestToken"
                  exact
                  component={routes.ConfirmPassword}
                />
                <Route
                  path="/:lang/email/confirm/:requestToken"
                  exact
                  component={routes.ConfirmEmail}
                />
                <Route
                  path="/:lang/email/change/:requestToken"
                  exact
                  component={routes.ChangeEmail}
                />
                <Route
                  path="/:lang/pages/:pageName"
                  exact
                  component={routes.CustomPage}
                />
                <Route path="*" component={Redirector} />
              </Switch>
            </Main>
          </routes.Language>
        </Route>
      </Switch>
      <Route path="/" exact render={() => <Redirect to={`/en/`} />} />
    </>
  )
}

export default Outer
