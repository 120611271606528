import { lazy } from 'react'

export { default as Language } from './Language/Language'

export const CellarPage = lazy(() => import('./CellarPage/CellarPage'))
export const Checkout = lazy(() => import('./Checkout/Checkout'))
export const CheckoutPage = lazy(() => import('./CheckoutPage/CheckoutPage'))
export const CheckoutCompletePage = lazy(() =>
  import('./CheckoutCompletePage/CheckoutCompletePage')
)
export const Profile = lazy(() => import('./Profile/Profile'))
export const ProfilePage = lazy(() => import('./ProfilePage/ProfilePage'))
export const ConfirmPassword = lazy(() => import('./ConfirmPassword/ConfirmPassword'))
export const ChangeEmail = lazy(() => import('./ChangeEmail/ChangeEmail'))
export const ConfirmEmail = lazy(() => import('./ConfirmEmail/ConfirmEmail'))
export const Shops = lazy(() => import('./Shops/Shops'))
export const ShopsPage = lazy(() => import('./ShopsPage/ShopsPage'))
export const SignupPage = lazy(() => import('./SignupPage/SignupPage'))
export const LoginPage = lazy(() => import('./LoginPage/LoginPage'))
export const CustomPage = lazy(() => import('./CustomPage/CustomPage'))
export const Public = lazy(() => import('./Public/Public'))
export const PublicExpensePage = lazy(() =>
  import('./PublicExpensePage/PublicExpensePage')
)
