import React from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Toolbar, Typography } from '@mui/material'

import { useCellarContext, useUserContext } from '../../contexts'

import MenuButton from './MenuButton/MenuButton'
import PersonButton from './PersonButton/PersonButton'
import CartButton from './CartButton/CartButton'
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher'

const PREFIX = 'Navbar'

const classes = {
  spacer: `${PREFIX}-spacer`,
  welcome: `${PREFIX}-welcome`,
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    flex: 10,
  },

  [`& .${classes.welcome}`]: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    marginRight: theme.spacing(2),
  },
}))

const Navbar = () => {
  const { user } = useUserContext()
  const { club } = useCellarContext()

  return (
    <StyledAppBar position="relative" id="app-bar">
      <Toolbar>
        {club && <MenuButton />}
        <div className={classes.spacer}></div>
        {user && (
          <Typography
            variant="body1"
            color="inherit"
            noWrap
            className={classes.welcome}
            id="app-bar__user-name"
          >
            {user.email}
          </Typography>
        )}
        {user && <CartButton />}
        {user && <PersonButton />}
        <LanguageSwitcher />
      </Toolbar>
    </StyledAppBar>
  )
}

export default Navbar
