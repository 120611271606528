import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { IAppParams } from '../../../types'

const useOnLanguageChange = (params: IAppParams) => {
  const history = useHistory()
  const location = useLocation()
  const { lang } = params

  const onLanguageChange = useCallback(
    (language: string) => {
      const changedUrl =
        location.pathname.replace(`/${lang}`, `/${language}`) + location.search
      history.replace(changedUrl)
    },
    [history, lang, location.pathname, location.search]
  )

  return onLanguageChange
}

export default useOnLanguageChange
