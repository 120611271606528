import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

const anyModule = module as any
if (anyModule.hot) {
  anyModule.hot.accept()
}
