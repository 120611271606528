import React from 'react'
import { styled } from '@mui/material/styles'
import { useHistory, useParams } from 'react-router-dom'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { IconButton, Badge } from '@mui/material'

import { IAppParams } from '../../../types'
import { useAppContext, useShopsContext, useUserContext } from '../../../contexts'

const PREFIX = 'CartButton'

const classes = {
  white: `${PREFIX}-white`,
}

const StyledIconButton = styled(IconButton)(() => ({
  [`&.${classes.white}`]: {
    color: '#fff',
  },
}))

const CartButton = () => {
  const params: IAppParams = useParams()
  const history = useHistory()
  const { user } = useUserContext()
  const { setShowCart } = useAppContext()
  const { totalCartQuantity } = useShopsContext()
  const { shopPageId } = params

  if (!user || !shopPageId) {
    return null
  }

  return (
    <StyledIconButton
      className={classes.white}
      onClick={() => {
        if (!window.location.pathname.includes('shops')) {
          history.push(`/${params.lang}/shop-pages/${shopPageId}`)
        }
        setShowCart(true)
        window.scrollTo({ top: 0 })
      }}
      id="app-bar__cart-button"
      size="large"
    >
      {totalCartQuantity ? (
        <Badge badgeContent={totalCartQuantity} color="secondary" data-testid="badge">
          <ShoppingCartIcon />
        </Badge>
      ) : (
        <ShoppingCartIcon />
      )}
    </StyledIconButton>
  )
}

export default CartButton
