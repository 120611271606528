import React, { createContext, ReactNode, useState, useEffect, useCallback } from 'react'

import { IAppParams } from '../../types'
import * as api from '../../api'

type IProps = {
  children: ReactNode
  params: IAppParams
}

type ContextProps = {
  profile?: any
  readProfile: () => Promise<any>
  updateProfile: (data: any) => Promise<any>
  requestCardSetupIntent: () => Promise<any>
  detachPaymentMethod: (paymentMethodId: string) => Promise<any>
}

export const ProfileContext = createContext<ContextProps>({} as ContextProps)
const { Provider } = ProfileContext

export const ProfileProvider = ({ children, params }: IProps) => {
  const [profile, setProfile] = useState<any>()
  const { lang } = params

  const readProfile = useCallback(
    () =>
      api.readProfile({ params }).then((response: any) => {
        setProfile(response.data)
        return Promise.resolve(response)
      }).catch((err: Error) => {
        console.error(err)
      }),
    [params]
  )

  const updateProfile = useCallback(
    (body: any) =>
      api.updateProfile({ params, body }).then((response) => {
        setProfile({
          ...profile,
          ...response.data,
        })
        return Promise.resolve(response)
      }),
    [params, profile]
  )

  const requestCardSetupIntent = useCallback(
    () => api.requestCardSetupIntent({ params }),
    [params]
  )

  const detachPaymentMethod = useCallback(
    (paymentMethodId: string) =>
      api.detachPaymentMethod({ params: { ...params, paymentMethodId } }),
    [params]
  )

  useEffect(() => {
    readProfile()
  }, [lang]) // eslint-disable-line

  return (
    <Provider
      value={{
        profile,
        readProfile,
        updateProfile,
        requestCardSetupIntent,
        detachPaymentMethod,
      }}
    >
      {children}
    </Provider>
  )
}
