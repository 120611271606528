import { useCallback } from 'react'

interface IUseRemoveFromCart {
  cart: any
  setCart: (cart: any) => void
}

export interface IRemoveFromCart {
  shopId: string
  productId: string
}

const useRemoveFromCart = ({ cart, setCart }: IUseRemoveFromCart) => {
  const removeFromCart = useCallback(
    ({ shopId, productId }: IRemoveFromCart) => {
      const cartShop = (cart[shopId] = cart[shopId] || {})

      delete cartShop[productId]
      setCart({ ...cart })
    },
    [cart, setCart]
  )

  return removeFromCart
}

export default useRemoveFromCart
