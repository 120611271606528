import React from 'react'
import { styled } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import { useAppContext } from '../../../contexts'

const PREFIX = 'MenuButton'

const classes = {
  icon: `${PREFIX}-icon`,
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.icon}`]: {
    color: '#fff',
  },
}))

const MenuButton = () => {
  const { setDrawerOpen } = useAppContext()

  return (
    <StyledIconButton
      className={classes.icon}
      onClick={() => setDrawerOpen(true)}
      size="large"
    >
      <MenuIcon />
    </StyledIconButton>
  )
}

export default MenuButton
