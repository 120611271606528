import React, { useCallback } from 'react'
import { styled } from '@mui/material/styles'
import { Link, useParams } from 'react-router-dom'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { IAppParams } from '../../types'
import { useAppContext, useCellarContext, useUserContext } from '../../contexts'
import { FormattedMessage } from 'react-intl'

const PREFIX = 'SideMenu'

const classes = {
  drawer: `${PREFIX}-drawer`,
  logo: `${PREFIX}-logo`,
  list: `${PREFIX}-list`,
  listSubheader: `${PREFIX}-listSubheader`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  userInfo: `${PREFIX}-userInfo`,
  menuLabel: `${PREFIX}-menuLabel`,
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    '& > .MuiDrawer-paper': {
      minWidth: '250px',
    },
  },

  [`& .${classes.logo}`]: {
    height: '200px',
    width: '200px',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.list}`]: {
    paddingTop: 0,
    '&::after': {
      content: '" "',
      display: 'block',
      width: '50px',
      borderTop: '1px solid',
      marginLeft: theme.spacing(2),
      position: 'relative',
      top: theme.spacing(1),
    },
  },

  [`& .${classes.listSubheader}`]: {
    lineHeight: '30px',
    fontSize: '80%',
    fontWeight: 600,
  },

  [`& .${classes.listItemIcon}`]: {
    minWidth: '30px',
  },

  [`& .${classes.userInfo}`]: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },

  [`& .${classes.menuLabel}`]: {
    display: 'flex',
  },
}))

const ListItemButton = ({ iconClassName, to, title, onClick, Icon }: any) => {
  return (
    <ListItem button component={Link} to={to} onClick={onClick}>
      {!!Icon && <ListItemIcon className={iconClassName}>{<Icon />}</ListItemIcon>}
      <ListItemText primary={title} />
    </ListItem>
  )
}

const SideMenu = () => {
  const { drawerOpen, setDrawerOpen } = useAppContext()
  const { setUser } = useUserContext()
  const { config, club } = useCellarContext()
  const params: IAppParams = useParams()
  const { lang } = params

  const profilePath = `/${lang}/profile`
  const loginPath = `/${lang}/login`
  const signupPath = `/${lang}/signup`
  const homePath = `/${lang}`

  const closeDrawer = useCallback(() => setDrawerOpen(false), [setDrawerOpen])
  const handleLogout = useCallback(() => {
    window.localStorage.removeItem('wa-token')
    setUser(null)
    window.location.reload()
    closeDrawer()
  }, [closeDrawer, setUser])

  if (!club) {
    return null
  }

  const logoUrl = `${process.env.REACT_APP_IMGIX_BASE_URL}${config.logo}?fm=webp&w=600&h=600`

  return (
    <StyledDrawer
      className={classes.drawer}
      anchor={'left'}
      open={drawerOpen}
      onClose={closeDrawer}
    >
      <img src={logoUrl} alt="logo" className={classes.logo} />
      <List className={classes.list}>
        <ListItem button component={Link} to={homePath} onClick={closeDrawer}>
          <ListItemText primary={<b>Home</b>} />
        </ListItem>
      </List>
      <List className={classes.list} dense>
        <ListSubheader className={classes.listSubheader}>SHOP</ListSubheader>
        {club.shopPages.map(({ shopPage }: any, i: number) => (
          <div key={i}>
            <ListItemButton
              to={`/${lang}/shop-pages/${shopPage.slug}`}
              title={shopPage.title}
              onClick={closeDrawer}
            />
            {shopPage.shops.map(({ shop }: any, is: number) => (
              <ListItemButton
                to={`/${lang}/shop-pages/${shopPage.slug}/shops/${shop.id}`}
                title={`- ${shop.title}`}
                onClick={closeDrawer}
                key={is}
              />
            ))}
          </div>
        ))}
      </List>
      {!!club.pages.length && (
        <List className={classes.list} dense>
          <ListSubheader className={classes.listSubheader}>IN EVIDENZA</ListSubheader>
          {club.pages.map(({ page }: any, i: number) => (
            <ListItemButton
              to={`/${lang}/pages/${page.id}`}
              title={page.title}
              onClick={closeDrawer}
              key={i}
            />
          ))}
        </List>
      )}
      {config.user && (
        <List dense>
          <ListSubheader>
            <p style={{ lineHeight: '18px' }}>
              <b>
                {config.user.firstName} {config.user.lastName}
              </b>
              <br />
              <span>{config.user.email}</span>
            </p>
          </ListSubheader>
          <ListItem button component={Link} to={profilePath} onClick={closeDrawer}>
            <ListItemIcon className={classes.listItemIcon}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="sidebar.profile" />} />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon className={classes.listItemIcon}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="sidebar.logout" />} />
          </ListItem>
        </List>
      )}
      {!config.user && (
        <List dense>
          <ListItem button component={Link} to={loginPath} onClick={closeDrawer}>
            <ListItemIcon className={classes.listItemIcon}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="authRequired.goToLogin" />} />
          </ListItem>
          {!config?.club?.settings.requiresInvitation && (
            <ListItem button component={Link} to={signupPath} onClick={closeDrawer}>
              <ListItemIcon className={classes.listItemIcon}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={<FormattedMessage id="authRequired.goToSignup" />} />
            </ListItem>
          )}
        </List>
      )}
    </StyledDrawer>
  )
}

export default SideMenu
