import React, { createContext, ReactNode, useState, useEffect } from 'react'
import { IntlProvider } from 'react-intl'

import it from '../../translations/it.json'
import en from '../../translations/en.json'

type IProps = {
  children: ReactNode
  params: {
    lang: string
  }
  onLanguageChange: (language: string) => void
}

type ContextProps = {
  translation: any
  language: string
  setLanguage: React.Dispatch<React.SetStateAction<string>>
}

const messages: any = {
  it,
  en,
}

export const LanguageContext = createContext<ContextProps>({} as ContextProps)
const { Provider } = LanguageContext

export const LanguageProvider = ({ children, params, onLanguageChange }: IProps) => {
  const { lang } = params
  const [language, setLanguage] = useState(lang)
  const [translation, setTranslation] = useState(
    messages[language] ? messages[language] : messages.en
  )

  useEffect(() => {
    if (lang === language) {
      return
    }
    setTranslation(messages[language] || messages.en)
    onLanguageChange(language)
  }, [lang, language, onLanguageChange])

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Provider
        value={{
          translation,
          language,
          setLanguage,
        }}
      >
        {children}
      </Provider>
    </IntlProvider>
  )
}
