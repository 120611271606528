import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import querystring from 'query-string'
import { Dialog, DialogActions, Button, DialogTitle, DialogContent } from '@mui/material'

import { IAppParams } from '../../types'
import { useCellarContext } from '../../contexts'

interface IAuthRequiredDialogProps {
  params: IAppParams
  open: boolean
  onClose: (goBack: boolean) => void
}

const AuthRequiredDialog = ({ params, open, onClose }: IAuthRequiredDialogProps) => {
  const history = useHistory()
  const { config } = useCellarContext()
  const { lang } = params
  const requiresLogin = !!config?.club?.settings.requiresLogin
  const requiresInvitation = !!config?.club?.settings.requiresInvitation

  const onClick = useCallback(
    page => {
      const query = querystring.parse(window.location.search)
      const previousReturnUrl = query.returnUrl as string
      const actualReturnUrl = window.location.pathname.includes('login')
        ? ''
        : window.location.pathname
      const returnUrl = previousReturnUrl || actualReturnUrl
      history.replace(`/${lang}/${page}?returnUrl=${encodeURIComponent(returnUrl)}`)
      onClose(false)
    },
    [history, lang, onClose]
  )

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' && requiresLogin) {
          return
        }
        onClose(true)
      }}
      aria-labelledby={'authRequired.label'}
      disableEnforceFocus
      disableAutoFocus
      style={{ zIndex: 101 }}
    >
      <DialogTitle id={`authRequired.label`}>
        <FormattedMessage id={'authRequired.title'} />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage id={'authRequired.message'} />
      </DialogContent>
      <DialogActions>
        {!requiresLogin && (
          <Button onClick={() => onClose(true)}>
            <FormattedMessage id="general.cancel" />
          </Button>
        )}
        {!requiresInvitation && (
          <Button onClick={() => onClick('signup')} color="primary">
            <FormattedMessage id="authRequired.goToSignup" />
          </Button>
        )}
        <Button onClick={() => onClick('login')} color="primary">
          <FormattedMessage id="authRequired.goToLogin" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AuthRequiredDialog
