import React from 'react'
import { styled } from '@mui/material/styles'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { useLanguageContext } from '../../../contexts'

const PREFIX = 'LanguageSwitcher'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.root}`]: {
    color: '#fff',
  },
}))

const LanguageSwitcher = () => {
  const { language, setLanguage } = useLanguageContext()

  const handleChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value)
  }

  return (
    <StyledFormControl id="app-bar__language-switcher">
      <Select
        className={classes.root}
        value={language}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'language switcher' }}
        variant="standard"
      >
        <MenuItem value={'it'}>IT</MenuItem>
        <MenuItem value={'en'}>EN</MenuItem>
      </Select>
    </StyledFormControl>
  )
}

export default LanguageSwitcher
