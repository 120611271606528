import React, { createContext, useEffect, useState } from 'react'

import { useProfileContext, useShopsContext } from '../../contexts'

interface ICheckoutContextValues {
  howToPay: string
  setHowToPay: React.Dispatch<React.SetStateAction<string>>
  cardName: string
  setCardName: React.Dispatch<React.SetStateAction<string>>
  savePaymentMethod: boolean
  setSavePaymentMethod: React.Dispatch<React.SetStateAction<boolean>>
  showListingsStep: boolean
}

export const CheckoutContext = createContext<ICheckoutContextValues>(
  {} as ICheckoutContextValues
)
const { Provider } = CheckoutContext

export const CheckoutProvider = ({ children }: React.PropsWithChildren<any>) => {
  const { profile } = useProfileContext()
  const { setPaymentMethodId, shippingMode, shopPage } = useShopsContext()
  const [howToPay, setHowToPay] = useState(
    profile?.paymentMethods?.data.length ? 'saved' : 'new'
  )
  const [cardName, setCardName] = useState('')
  const [savePaymentMethod, setSavePaymentMethod] = useState(false)
  const [showListingsStep, setShowListingsStep] = useState(false)

  useEffect(() => {
    if (['shipping', 'ship_to_billing'].includes(shippingMode)) {
      setShowListingsStep(true)
    } else {
      setShowListingsStep(false)
    }
  }, [shippingMode, shopPage])

  useEffect(() => {
    if (howToPay === 'new') {
      setPaymentMethodId('')
    }
  }, [howToPay, setPaymentMethodId])

  if (!profile) {
    return null
  }

  return (
    <Provider
      value={{
        howToPay,
        setHowToPay,
        cardName,
        setCardName,
        savePaymentMethod,
        setSavePaymentMethod,
        showListingsStep,
      }}
    >
      {children}
    </Provider>
  )
}
