import { useCallback } from 'react'

interface IUseAddToCart {
  cart: any
  setCart: (cart: any) => void
}

export interface IAddToCart {
  shopId: string
  productId: string
  quantity: number
}

const useAddToCart = ({ cart, setCart }: IUseAddToCart) => {
  const addToCart = useCallback(
    ({ shopId, productId, quantity }: IAddToCart) => {
      const cartShop = (cart[shopId] = cart[shopId] || {})
      const shopProduct = (cartShop[productId] = cartShop[productId] || {})

      shopProduct.quantity = shopProduct.quantity || 0
      shopProduct.quantity += quantity
      setCart({ ...cart })
    },
    [cart, setCart]
  )

  return addToCart
}

export default useAddToCart
