import React, { createContext, ReactNode, useState, useEffect } from 'react'

interface IUser {
  firstName: string
  lastName: string
  picture: string
  email: string
}

type IUserContext = {
  user: IUser | null
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>
  isAuth: boolean
}

type IProps = {
  children: ReactNode
}

export const UserContext = createContext<IUserContext>({} as IUserContext)
const { Provider } = UserContext

export const UserProvider = ({ children }: IProps) => {
  const [user, setUser] = useState<IUser | null>(null)
  const [isAuth, setIsAuth] = useState(false)

  useEffect(() => {
    setIsAuth(!!user)
  }, [user])

  return <Provider value={{ user, setUser, isAuth }}>{children}</Provider>
}
