import { useContext } from 'react'

import { AppContext } from './AppContext/AppContext'
import { CellarContext } from './CellarContext/CellarContext'
import { CheckoutContext } from './CheckoutContext/CheckoutContext'
import { LanguageContext } from './LanguageContext/LanguageContext'
import { ProfileContext } from './ProfileContext/ProfileContext'
import { PublicContext } from './PublicContext/PublicContext'
import { ShopContext } from './ShopContext/ShopContext'
import { ShopsContext } from './ShopsContext/ShopsContext'
import { UserContext } from './UserContext/UserContext'

export const useAppContext = () => useContext(AppContext)
export const useCellarContext = () => useContext(CellarContext)
export const useCheckoutContext = () => useContext(CheckoutContext)
export const useLanguageContext = () => useContext(LanguageContext)
export const useProfileContext = () => useContext(ProfileContext)
export const usePublicContext = () => useContext(PublicContext)
export const useShopContext = () => useContext(ShopContext)
export const useShopsContext = () => useContext(ShopsContext)
export const useUserContext = () => useContext(UserContext)
