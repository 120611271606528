{
  "general.addToCart": "Aggiungi al carrello",
  "general.total": "Totale: ",
  "general.discount": "Sconto: ",
  "general.listPrice": "Prezzo di listino: ",
  "general.price": "Prezzo",
  "general.back": "Indietro",
  "general.firstName": "Nome",
  "general.lastName": "Cognome",
  "general.password": "Password",
  "general.close": "Chiudi",
  "general.confirm": "Conferma",
  "general.cancel": "Annulla",
  "general.email": "Email",
  "shops.backToMainPage": "Torna alla pagina shop",
  "shops.productCard.notAvailable": "Non disponibile",
  "fields.phone": "Numero di telefono",
  "fields.email": "Email",
  "fields.repeatEmail": "Ripeti email",
  "fields.password": "Password",
  "fields.firstName": "Nome",
  "fields.lastName": "Cognome",
  "fields.company": "Azienda",
  "fields.street": "Via/Corso/Piazza",
  "fields.number": "Numero civico",
  "fields.city": "Città",
  "fields.province": "Provincia",
  "fields.state": "Regione/Stato",
  "fields.country": "Nazione",
  "fields.zip": "CAP",
  "fields.accountEmail": "Email dell'account",
  "fields.cardName": "Nome del titolare della carta",
  "fields.howToPay": "Come desideri pagare?",
  "fields.howToPay.saved": "Metodo di pagamento salvato",
  "fields.howToPay.new": "Nuovo metodo di pagamento",
  "fields.savePaymentMethod": "Salva questo metodo di pagamento per ordini futuri",
  "fields.info": "Info",
  "fields.person": "Tipologia cliente",
  "fields.person.true": "Cliente privato",
  "fields.person.false": "Azienda",
  "fields.fiscalCompany": "Ragione sociale",
  "fields.code": "Partita IVA",
  "fields.cf": "Codice fiscale",
  "fields.pec": "PEC",
  "fields.sdi": "Codice SDI",
  "home.view": "Vedi",
  "navbar.welcome": "Bentornato",
  "profile.edit": "Cambia il tuo profilo",
  "profile.info.title": "Informazioni personali",
  "paymentMethod.title": "Dati di pagamento",
  "paymentMethod.preferred": "Scegli metodo predefinito",
  "paymentMethod.manage": "Gestisci",
  "paymentMethod.addNew": "Aggiungi nuova carta",
  "profile.orders.title": "I tuoi ordini",
  "shipping.title": "Indirizzo di spedizione",
  "shipping.selected": "Selezionato",
  "shipping.manage": "Gestisci",
  "shipping.completeThisAddress": "Clicca qui per completare questo indirizzo",
  "shipping.addNew": "Aggiungi nuovo indirizzo",
  "billing.title": "Dati acquirente",
  "billing.selected": "Selezionato",
  "billing.manage": "Gestisci",
  "billing.completeThisBilling": "Clicca qui per completare i dati",
  "billing.addNew": "Aggiungi nuovo acquirente",
  "billing.invoice": "Desidero ricevere la fattura anziché la ricevuta",
  "billing.invoiceMandatory": "I dati di fatturazione sono obbligatori",
  "billing.invoiceAddress": "Indirizzo di fatturazione",
  "billing.receiptAddress": "Indirizzo di ricevuta",
  "billing.invoiceData": "Dati di fatturazione",
  "confirmPassword.change": "Cambia la tua password",
  "confirmPassword.confirmPassword": "Conferma la password",
  "changeEmail.title": "Cambia la tua email",
  "changeEmail.success": "Email modificata con successo",
  "changeEmail.error": "Token per modifica email non valido",
  "user.changeEmail": "Sei sicuro di voler cambiare la tua Email?",
  "user.changePassword": "Sei sicuro di voler cambiare la tua password?",
  "user.resetPassword": "Ripristina password",
  "login.title": "Login",
  "login.notRegistered": "Ancora non sei registrato? ",
  "login.requestAccess": "Richiedi accesso",
  "signup.registrationTitle": "Conferma iscrizione",
  "signup.invitationTitle": "Richiedi accesso",
  "signup.otherDataTitle": "Altri dati",
  "signup.privacy.service": "Accetto <pp>privacy policy</pp> e <tc>termini di servizio</tc>",
  "signup.privacy.accept": "Accetto",
  "signup.alreadyRegistered": "Già registrato? ",
  "signup.submit": "Conferma",
  "dialogs.profileChange.title": "Modifica profilo",
  "dialogs.profileChange.message": "Inserisci il nuovo valore per il campo desiderato",
  "dialogs.profileChange.success": "Dato aggiornato con successo",
  "dialogs.emailChange.title": "Cambia indirizzo email",
  "dialogs.emailChange.message": "Premendo conferma ti invieremo un email all'indirizzo attualmente in uso con ulteriori informazioni per procedere all'aggiornamento al nuovo indirizzo",
  "dialogs.emailChange.success": "Email inviata con successo, puoi chiudere il popup",
  "dialogs.passwordReset.title": "Ripristina password",
  "dialogs.passwordReset.message": "Inserisci l'email dell'account di cui vuoi ripristinare la password, ti invieremo un email con un link per procedere al ripristino",
  "dialogs.passwordReset.success": "Ti abbiamo inviato una mail con le istruzioni per proseguire",
  "dialogs.addCard.title": "Inserisci carta di credito",
  "dialogs.addCard.message": "Questa carta rimarrà associata al tuo profilo e potrà essere usata per fare acquisiti senza doverla reinserire",
  "dialogs.address.title": "Indirizzo",
  "dialogs.address.message": "Compila l'indirizzo inserendo tutti i campi obbligatori marcati con *",
  "cart.empty": "Il tuo carrello è vuoto",
  "cart.compact": "Hai {quantity, plural, one {# prodotto} other {# prodotti}} nel carrello, clicca qui per vedere il carrello e andare a checkout",
  "cart.pricePerItem": "{currencySymbol} {price}",
  "cart.openCart": "Apri carrello e checkout",
  "cart.closeCart": "Chiudi e continua gli acquisti",
  "cart.addedToCart": "Aggiunto!",
  "cart.subtotal": "Subtotale",
  "cart.discount": "Sconto",
  "cart.shipping": "Costi di spedizione",
  "cart.total": "Totale",
  "cart.goToCheckout": "Vai al checkout",
  "cart.quantities": "Prodotti di tipo <b>{productType, select, wine {vino} liquid {liquido} food {alimentare} generic {generico} other {}}</b> con <b>{field, select, format {formato} weight{peso} other {}}</b> pari a <b>{fieldValue, number, decimal}{field, select, format {l} weigth {kg} other {}}</b> devono essere aggiunti al carrello in multipli di <b>{quantity}</b> {check, plural, =0 {} other {({missing} {missing, plural, =1 {mancante} other {mancanti}})}}",
  "checkout.shipping.chooseShippingMode": "Scegli spedizione",
  "checkout.shipping.shipToBilling": "Spedisci a {formattedAddress}",
  "checkout.shipping.shipToAddress": "Spedisci ad altro indirizzo",
  "checkout.shipping.pickup": "Voglio ritirarlo personalmente",
  "checkout.listings.title": "Scegli tipologia di spedizione",
  "checkout.listings.noShippingAvailable": "Non sono disponibili opzioni di spedizione per servire la nazione che hai selezionato",
  "checkout.payment": "Pagamento",
  "checkout.newPayment": "Inserisci i dati di pagamento",
  "checkout.savedPayment": "Scegli un metodo di pagamento esistente",
  "checkout.confirmation.data": "Scegli il metodo da utilizzare per il pagamento di questo ordine",
  "checkout.completed.title": "Il tuo ordine è completato!",
  "checkout.completed.message": "Inizieremo a processare il tuo ordine il prima possibile, ti abbiamo appena inviato un'email con i dati relativi al tuo acquisto. Inoltre, questo ordine ora è presente sul tuo profilo.",
  "checkout.completed.goToOrders": "Controlla i tuoi ordini",
  "checkout.completed.backToShop": "Torna allo shop",
  "checkout.openSummary": "Mostra dettagli dell'ordine",
  "checkout.hideSummary": "Nascondi dettagli dell'ordine",
  "checkout.steps.orderData": "Informazioni",
  "checkout.steps.shipping": "Spedizione",
  "checkout.steps.payment": "Pagamento",
  "checkout.buttons.backToShop": "Torna allo shop",
  "checkout.buttons.backToOrderData": "Torna a informazioni",
  "checkout.buttons.backToShipping": "Torna a spedizione",
  "checkout.buttons.goToShipping": "Vai a spedizione",
  "checkout.buttons.goToPayment": "Vai al pagamento",
  "checkout.buttons.checkout": "Concludi ordine",
  "checkout.discount": "Sconto: {currencySymbol} {discount}",
  "checkout.contact": "Contatto",
  "checkout.selectedListing": "Spedizione selezionata",
  "sidebar.profile": "Il mio profilo",
  "sidebar.logout": "Esci",
  "authRequired.title": "Autenticazione richiesta",
  "authRequired.message": "Per procedere è necessario essere loggati nel proprio account utente",
  "authRequired.goToLogin": "Accedi",
  "authRequired.goToSignup": "Registrati",
  "UNAUTHORIZED": "Email o password non validi",
  "INVITATION_REQUIRED": "E' necessario un invito per iscriversi",
  "ALREADY_INVITED": "Un invito è già stato inviato a questa email",
  "ALREADY_REGISTERED": "Questa email è già registrata",
  "INVITATION_SENT": "Abbiamo inviato una mail all'indirizzo specificato con le istruzioni necessarie per completare la registrazione",
  "PASSWORD_TOO_SHORT": "La password deve essere lunga almeno 8 caratteri",
  "EMAIL_CHANGE_CHOOSE_SUCCESS": "Abbiamo inviato una mail all'indirizzo scelto per confermare la modifica",
  "EMAIL_CHANGE_CHOOSE_ERROR": "Questo indirizzo per la modifica dell'indirizzo email non è più valido",
  "INVITATION_TOKEN_INVALID": "Questo link di invito è errato o scaduto, siete pregati di accedere dal link di invito più recente che vi è stato inviato"
}
