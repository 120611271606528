import { IAppParams } from './types'
import { interpolate } from './utils/routing'

const baseUrl = process.env.REACT_APP_API_BASE_URL!

type TMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
interface IGetOptions {
  method: TMethod
  token: string | null
  body: any
  params: any
}

const getOptions = ({ method, token, body, params }: IGetOptions) => {
  let appToken = window?.localStorage?.getItem('wa-app-token')
  if (!appToken) {
    appToken = Math.random().toString() + Date.now()
    window?.localStorage?.setItem('wa-app-token', appToken)
  }

  const options = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Accept-Language': params.lang || 'en',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-WineAround-Origin': window.location.hostname,
      'WA-App-Token': appToken?.toString() ?? '',
    },
    body: JSON.stringify(body),
  }

  if (!token) {
    delete (options as any).headers?.Authorization
  }
  if (method === 'GET' || method === 'DELETE' || !body) {
    delete (options as any).body
  }

  return options
}

interface ICheckParams {
  (params: IAppParams): boolean
}

interface ICheckParamsFactory {
  (check: IAppParams): ICheckParams
}

interface IRequestInterceptorFunction {
  (payload: any): boolean
}

interface IRequestInterceptor extends IAppParams {
  interceptor: IRequestInterceptorFunction
  checkParams: ICheckParams
  exclude: string[]
  [key: string]: any
}

const requestInterceptors: IRequestInterceptor[] = []

const checkParamsFactory: ICheckParamsFactory = check => {
  return params =>
    Object.keys(check).reduce((acc: boolean, key: string) => {
      const result = check[key] === params[key]
      return acc && result
    }, true)
}

export const addRequestInterceptor = (
  interceptor: IRequestInterceptorFunction,
  params: IAppParams,
  exclude: string[] | undefined = []
): void => {
  const checkParams = checkParamsFactory(params)
  const requestInterceptor = {
    interceptor,
    checkParams,
    exclude,
    ...params,
  }
  requestInterceptors.push(requestInterceptor)
}

export const removeRequestInterceptor = (
  interceptor: IRequestInterceptorFunction
): void => {
  const index = requestInterceptors.findIndex(r => r.interceptor === interceptor)
  if (index >= 0) {
    requestInterceptors.splice(index, 1)
  }
}

interface IApiRequest {
  params?: IAppParams
  query?: any
  body?: any
}

interface ISimpleRequest extends IApiRequest {
  path: string
}

interface IRequest extends ISimpleRequest {
  method: TMethod
}

interface IApiRequestFunction {
  (data: IApiRequest): Promise<any>
}

const request = async <T = any>({
  path,
  params = {},
  query,
  body,
  method,
}: IRequest): Promise<T | null> => {
  const url = interpolate({ path, params, query, baseUrl })
  const token = window.localStorage.getItem('wa-token')
  const response = await fetch(
    url.toString(),
    getOptions({ method, token, body, params })
  )

  if (response.status === 204) {
    return null
  }

  let payload: unknown = await response.text()
  try {
    payload = JSON.parse(payload as string)
  } catch (e) {
    // empty
  }

  const intercepted = requestInterceptors.reduce((acc, r) => {
    const check = r.checkParams(params) && !r.exclude.includes(path)
    const result = check && r.interceptor(response)
    return result || acc
  }, false)

  if (intercepted) {
    Object.assign(payload as any, { intercepted })
  }

  if (response.status >= 400) {
    const apiError = new Error('API Error')
    Object.assign(apiError, payload)
    throw apiError
  }

  return payload as T
}
export default request

export const get = <T>({ path, params, query }: ISimpleRequest): Promise<T | null> =>
  request<T>({ path, params, query, method: 'GET' })
export const post = <T>({
  path,
  params,
  query,
  body,
}: ISimpleRequest): Promise<T | null> =>
  request<T>({ path, params, query, body, method: 'POST' })
export const put = <T>({
  path,
  params,
  query,
  body,
}: ISimpleRequest): Promise<T | null> =>
  request<T>({ path, params, query, body, method: 'PUT' })
export const patch = <T>({
  path,
  params,
  query,
  body,
}: ISimpleRequest): Promise<T | null> =>
  request<T>({ path, params, query, body, method: 'PATCH' })
export const del = <T>({ path, params, query }: ISimpleRequest): Promise<T | null> =>
  request<T>({ path, params, query, method: 'DELETE' })

export const getCustomStyle: IApiRequestFunction = ({ query }) => {
  return get({ path: '/api/v2/style', query })
}

export const getCustomTheme: IApiRequestFunction = ({ query }) => {
  return get({ path: '/api/v2/theme', query })
}

export const hostname: IApiRequestFunction = ({ query }) => {
  return get({ path: '/api/v2/hostname', query })
}

export const helloCellar: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/hello', params })
}

export const readCellar: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2', params })
}

export const readClub: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/club', params })
}

export const readClubMeta: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/club/meta', params })
}

export const readProfile: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/profile', params })
}

export const updateProfile: IApiRequestFunction = ({ params, body }) => {
  return patch({ path: '/api/v2/profile', params, body })
}

export const requestCardSetupIntent: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/profile/setup-intent', params })
}

export const detachPaymentMethod: IApiRequestFunction = ({ params }) => {
  return del({
    path: '/api/v2/profile/payment-methods/:paymentMethodId',
    params,
  })
}

export const changeEmailRequest: IApiRequestFunction = ({ params, body = {} }) => {
  return post({ path: '/api/v2/email/request', params, body })
}

export const changeEmailChoose: IApiRequestFunction = ({ params, body }) => {
  return post({
    path: '/api/v2/email/choose/:requestToken',
    params,
    body,
  })
}

export const changeEmailConfirm: IApiRequestFunction = ({ params }) => {
  return post({
    path: '/api/v2/email/confirm/:requestToken',
    params,
  })
}

export const resetPasswordRequest: IApiRequestFunction = ({ params, body = {} }) => {
  return post({ path: '/api/v2/password/request', params, body })
}

export const resetPasswordConfirm: IApiRequestFunction = ({ params, body }) => {
  return post({
    path: '/api/v2/password/confirm/:requestToken',
    params,
    body,
  })
}

export const login: IApiRequestFunction = ({ params, body }) => {
  return post({ path: '/api/v2/auth/signin', params, body })
}

export const signup: IApiRequestFunction = ({ params, body }) => {
  return post({
    path: '/api/v2/auth/signup/:requestToken',
    params,
    body,
  })
}

export const readInvite: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/auth/signup/:requestToken', params })
}

export const readShopPages: IApiRequestFunction = ({ params, query }) => {
  return get({ path: '/api/v2/shop-pages', params, query })
}

export const readShopPage: IApiRequestFunction = ({ params, query }) => {
  return get({ path: '/api/v2/shop-pages/:shopPageId', params, query })
}

export const readCart: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/shop-pages/:shopPageId/cart', params })
}

export const updateCart: IApiRequestFunction = ({ params, body }) => {
  return put({ path: '/api/v2/shop-pages/:shopPageId/cart', params, body })
}

export const processCart: IApiRequestFunction = ({ params, body, query }) => {
  return post({
    path: '/api/v2/shop-pages/:shopPageId/cart/o/process',
    params,
    body,
    query,
  })
}

export const getAvailableListings: IApiRequestFunction = ({ params, body, query }) => {
  return post({
    path: '/api/v2/shop-pages/:shopPageId/cart/r/listings',
    params,
    body,
    query,
  })
}

export const getShippingCosts: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/shop-pages/:shopPageId/shipping', params })
}

export const checkout: IApiRequestFunction = ({ params, body }) => {
  return post({ path: '/api/v2/shop-pages/:shopPageId/checkout', params, body })
}

export const readShops: IApiRequestFunction = ({ params, query }) => {
  return get({ path: '/api/v2/shop-pages/:shopPageId/shops', params, query })
}

export const readShop: IApiRequestFunction = ({ params, query }) => {
  return get({ path: '/api/v2/shop-pages/:shopPageId/shops/:shopId', params, query })
}

export const readProduct: IApiRequestFunction = ({ params, query }) => {
  return get({
    path: '/api/v2/shop-pages/:shopPageId/shop-pages/:shopId/products/:productId',
    params,
    query,
  })
}

export const listOrders: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/orders', params })
}

export const listExpenses: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/expenses', params })
}

export const readExpensePublic: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/expenses/public/:expensePublicId', params })
}

export const listGeoStates: IApiRequestFunction = ({ query }) => {
  return get({ path: '/api/v2/geo/states', query })
}

export const listGeoProvinces: IApiRequestFunction = ({ query }) => {
  return get({ path: '/api/v2/geo/provinces', query })
}

export const validateCoupon: IApiRequestFunction = ({ params }) => {
  return get({ path: '/api/v2/coupons/:couponCode/validate', params })
}
