import React, { createContext, ReactNode, useState, useEffect, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'

import * as api from '../../api'
import { IAppParams } from '../../types'
import { useUserContext } from '../../contexts'

type ContextProps = {
  init: boolean
  config: any
  cellar?: any
  club?: any
  clubMeta?: any
  inviteData?: any
  orders?: any[]
  expenses?: any[]
  couponCode: string
  setCouponCode: React.Dispatch<React.SetStateAction<string>>
  couponData: any
  validateCoupon: () => Promise<any>
  setCellar: React.Dispatch<React.SetStateAction<any>>
  helloCellar: () => Promise<any>
  readCellar: () => Promise<any>
  readClubMeta: () => Promise<any>
  readClub: () => Promise<any>
  // readProfile: Function
  readInviteData: (requestToken: string) => Promise<any>
  listOrders: () => Promise<any[]>
  listExpenses: () => Promise<any[]>
}

type IProps = {
  children: ReactNode
  params: IAppParams
}

export const CellarContext = createContext<ContextProps>({
  init: false,
  config: {},
} as ContextProps)
const { Provider } = CellarContext

export const CellarProvider = ({ children, params }: IProps) => {
  const { isAuth, setUser } = useUserContext()
  const [init, setInit] = useState(false)
  const [config, setConfig] = useState<any>({})
  const [cellar, setCellar] = useState<any>()
  const [club, setClub] = useState<any>()
  const [clubMeta, setClubMeta] = useState<any>({ fields: [] })
  const [inviteData, setInviteData] = useState<any>(null)
  const [orders, setOrders] = useState<any[]>([])
  const [expenses, setExpenses] = useState<any[]>([])
  const [couponCode, setCouponCode] = useState('')
  const [couponData, setCouponData] = useState<any>(null)
  const { lang } = params

  const readCellar = useCallback(async () => {
    const response = await api.readCellar({ params })
    setCellar(response.data)

    return response
  }, [params])

  const readClub = useCallback(async () => {
    const response = await api.readClub({ params })
    setClub(response.data)

    return response
  }, [params])

  const readClubMeta = useCallback(async () => {
    const response = await api.readClubMeta({ params })
    setClubMeta(response.data)

    return response
  }, [params])

  // const readProfile = useCallback(
  //   () =>
  //     api.readProfile({ params }).then((response: any) => {
  //       setUser(response.data)
  //       return Promise.resolve(response)
  //     }).catch((err: Error) => {
  //       console.error(err)
  //     }),
  //   [params, setUser]
  // )

  const readInviteData = useCallback(
    async requestToken => {
      const extendedParams = { ...params, requestToken }
      const response = await api.readInvite({ params: extendedParams })
      setInviteData(response.data)

      return response
    },
    [params, setInviteData]
  )

  const listOrders = useCallback(async () => {
    const response = await api.listOrders({ params })
    setOrders(response.data)

    return response
  }, [params])

  const listExpenses = useCallback(async () => {
    const response = await api.listExpenses({ params })
    setExpenses(response.data)

    return response
  }, [params])

  const helloCellar = useCallback(async () => {
    const response = await api.helloCellar({ params })
    setConfig(response.data)
    setUser(response.data.user)
    setInit(true)

    return response
  }, [params, setUser])

  const validateCoupon = useCallback(async () => {
    const params = { couponCode }
    const response = await api.validateCoupon({ params })
    setCouponData(response.data)

    return response
  }, [couponCode])

  const debouncedValidateCoupon = useDebouncedCallback(() => {
    validateCoupon()
  }, 400)
  useEffect(() => {
    debouncedValidateCoupon.callback()
  }, [couponCode, debouncedValidateCoupon])

  useEffect(() => {
    // TODO: should be handled with websockets
    helloCellar()
    readClub()
    // const interval = setInterval(() => {
    //   helloCellar()
    // }, 10000)
    // // TODO END
    // return () => clearInterval(interval)
  }, [lang, isAuth]) // eslint-disable-line

  return (
    <Provider
      value={{
        init,
        config,
        cellar,
        club,
        clubMeta,
        inviteData,
        orders,
        expenses,
        couponCode,
        setCouponCode,
        couponData,
        validateCoupon,
        setCellar,
        helloCellar,
        readCellar,
        readClub,
        readClubMeta,
        // readProfile,
        readInviteData,
        listOrders,
        listExpenses,
      }}
    >
      {children}
    </Provider>
  )
}
