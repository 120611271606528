import { useCallback, useEffect } from 'react'

import * as api from '../../api'
import { useUserContext } from '../../contexts'

const useAuthRequestInterceptor = (
  setAuthIntercepted: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { setUser } = useUserContext()

  const authRequestInterceptor = useCallback(
    response => {
      if (response.status === 401) {
        const { pathname } = window.location
        setUser(null)
        if (
          !pathname.includes('login') &&
          !pathname.includes('signup') &&
          !pathname.includes('password/confirm')
        ) {
          setAuthIntercepted(true)
          return true
        }
      }
      return false
    },
    [setUser, setAuthIntercepted]
  )

  useEffect(() => {
    api.addRequestInterceptor(authRequestInterceptor, {})
    return () => api.removeRequestInterceptor(authRequestInterceptor)
  }, [authRequestInterceptor])
}

export default useAuthRequestInterceptor
