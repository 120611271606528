import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import currencyMapSymbol from 'currency-map-symbol'

import * as api from '../../api'
import { IAppParams } from '../../types'
import { useCellarContext, useUserContext } from '../../contexts'

import useAddToCart, { IAddToCart } from './useAddToCart/useAddToCart'
import useRemoveFromCart, { IRemoveFromCart } from './useRemoveFromCart/useRemoveFromCart'
import useUpdateCartQuantity, {
  IUpdateCartQuantity,
} from './useUpdateCartQuantity/useUpdateCartQuantity'

export interface ICheckoutBody {
  paymentMethodId: string
  shippingId: string
  paymentIntentId?: string
  shippingMode?: string
}

export interface IShopsContextProps {
  shopPage: any
  currencySymbol: string
  checkout: (body: ICheckoutBody) => Promise<any>
  cart?: any
  blockCart: string | false
  cartProducts: any[]
  totalCartQuantity: number
  isUpdatingCart: boolean
  addToCart: (data: IAddToCart) => void
  removeFromCart: (data: IRemoveFromCart) => void
  updateCartQuantity: (data: IUpdateCartQuantity) => void
  processCart: () => Promise<any>
  amount: number
  discount: number
  total: number
  shippingCosts: number
  shipping?: any
  listings: any[]
  isLoadingListings: boolean
  getAvailableListings: () => Promise<any>
  // checkout params
  paymentMethodId: string
  setPaymentMethodId: React.Dispatch<React.SetStateAction<string>>
  shippingId: string
  setShippingId: React.Dispatch<React.SetStateAction<string>>
  billingId: string
  setBillingId: React.Dispatch<React.SetStateAction<string>>
  shippingMode: string
  setShippingMode: React.Dispatch<React.SetStateAction<string>>
  listingId: string
  setListingId: React.Dispatch<React.SetStateAction<string>>
}

export const ShopsContext = createContext<IShopsContextProps>({
  shopPage: null,
  setShopPage: () => void 0,
  cartProducts: [],
  totalCartQuantity: 0,
  total: 0,
  shippingCosts: 0,
} as any)
const { Provider } = ShopsContext

export interface IShopsProviderProps {
  children: ReactNode
  params: IAppParams
}

const getPersistedCart = (shopPageId: string) => {
  const persistedCart = window.localStorage.getItem(`wa-cart-${shopPageId}`) || ''
  try {
    return JSON.parse(persistedCart)
  } catch (e) {
    return {}
  }
}

export const ShopsProvider = ({ children, params }: IShopsProviderProps) => {
  const { lang, shopPageId } = params
  const { isAuth } = useUserContext()
  const { couponCode } = useCellarContext()

  const [shopPage, setShopPage] = useState<any>()
  const [currencySymbol, setCurrencySymbol] = useState('')
  const [blockCart, setBlockCart] = useState<string | false>(false)
  const [amount, setAmount] = useState(0)
  const [total, setTotal] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [totalCartQuantity, setTotalCartQuantity] = useState(0)
  const [shippingCosts, setShippingCosts] = useState(0)
  const [shipping, setShippingAddress] = useState<any>()
  const [isUpdatingCart, setIsUpdatingCart] = useState(false)
  const [cart, setCart] = useState<any>(getPersistedCart(shopPageId!))
  const [shippingMode, setShippingMode] = useState('')
  const [isLoadingListings, setIsLoadingListings] = useState(false)
  const [listings, setListings] = useState<any[]>([])
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const [shippingId, setShippingId] = useState('')
  const [billingId, setBillingId] = useState('')
  const [listingId, setListingId] = useState('')

  const persistCart = useCallback(
    (c: any = {}) => {
      window.localStorage.setItem(`wa-cart-${shopPageId}`, JSON.stringify(c))
      setCart(c)
    },
    [shopPageId]
  )

  const readShopPage = useCallback(
    () =>
      api
        .readShopPage({ params, query: { expand: true } })
        .then(response => {
          setShopPage(response.data)
        })
        .catch((err: Error) => {
          console.error(err)
        }),
    [params]
  )

  const processCart = useCallback(async () => {
    setIsUpdatingCart(true)
    try {
      const persistedCart = getPersistedCart(shopPageId!)
      const response = await api.processCart({
        params,
        body: persistedCart,
        query: { billingId, listingId, shippingId, shippingMode, couponCode },
      })
      const { stateName, countryName } = response.data
      persistCart(response.data.cart || {})
      setAmount(response.data.amount)
      setDiscount(response.data.discount)
      setShippingCosts(response.data.shippingCosts)
      setTotal(response.data.total)
      setShippingAddress({
        ...response.data.shipping,
        stateName,
        countryName,
      })
      setBlockCart(false)
      setIsUpdatingCart(false)

      return response
    } catch (e) {
      setBlockCart((e as any).reason || (e as Error).message || true)
    }
    setIsUpdatingCart(false)
  }, [
    billingId,
    couponCode,
    listingId,
    params,
    persistCart,
    shopPageId,
    shippingId,
    shippingMode,
  ])

  const getAvailableListings = useCallback(async () => {
    setIsLoadingListings(true)
    const response = await api.getAvailableListings({
      params,
      body: cart,
      query: { billingId, shippingId, shippingMode, couponCode },
    })
    setListings(response.data ?? [])
    setIsLoadingListings(false)

    return response
  }, [billingId, cart, params, shippingId, shippingMode, couponCode])

  const debouncedProcessCart = useDebouncedCallback(processCart, 800)
  const persistAndProcessCart = useCallback(
    updatedCart => {
      setIsUpdatingCart(true)
      persistCart(updatedCart)
      debouncedProcessCart.callback()
    },
    [debouncedProcessCart, persistCart]
  )

  // keep cart alive every ten minutes if user is logged in
  useEffect(() => {
    if (!isAuth) {
      return
    }
    const interval = setInterval(() => {
      debouncedProcessCart.callback()
    }, 10 * 60 * 1000)

    return () => clearInterval(interval)
  }, [debouncedProcessCart, isAuth])

  useEffect(() => {
    const quantity = Object.keys(cart).reduce((totalAcc, shopId) => {
      return (
        totalAcc +
        Object.keys(cart[shopId]).reduce((shopAcc, productId) => {
          return shopAcc + (cart[shopId][productId].quantity || 0)
        }, 0)
      )
    }, 0)
    setTotalCartQuantity(quantity)
  }, [cart])

  const addToCart = useAddToCart({ cart, setCart: persistAndProcessCart })
  const removeFromCart = useRemoveFromCart({ cart, setCart: persistAndProcessCart })
  const updateCartQuantity = useUpdateCartQuantity({
    cart,
    setCart: persistAndProcessCart,
  })

  const checkout = useCallback(
    body =>
      api.checkout({ params, body }).then(response => {
        return Promise.resolve(response)
      }),
    [params]
  )

  useEffect(() => {
    const fetchData = () => Promise.all([processCart(), readShopPage()])
    fetchData()

    // TODO: should be handled with websockets
    // const interval = setInterval(async () => {
    //   await readShopPage()

    //   if (!debouncedUpdateCart.pending() && !isUpdatingCart) {
    //     await readCart()
    //   }
    // }, 10000)
    // TODO END

    // return () => clearInterval(interval)
  }, [lang, processCart, readShopPage, shopPageId])

  const cartProducts = useMemo(() => {
    if (!shopPage || !Array.isArray(shopPage.shops) || !shopPage.shops.length) {
      return []
    }

    const { shops } = shopPage

    const cartShopIds = Object.keys(cart)
    const result = cartShopIds.reduce((acc, shopId) => {
      const shopProductIds = Object.keys(cart[shopId])
      const shopObject = shops.find(({ shop }: any) => shop.id === shopId) as any

      if (!shopObject) {
        return acc
      }

      const { shop } = shopObject
      const shopProducts = shopProductIds.map(productId => {
        const product = shop.items.find((item: any) => item.shopItem.id === productId)

        return { ...cart[shopId][productId], shopId, product }
      })

      return [...acc, ...shopProducts]
    }, [] as any[])

    return result
  }, [cart, shopPage])

  useEffect(() => {
    if (!shopPage?.currency) {
      return
    }

    const newCurrencySymbol = currencyMapSymbol(shopPage.currency)
    if (newCurrencySymbol !== currencySymbol) {
      setCurrencySymbol(newCurrencySymbol)
    }
  }, [shopPage]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Provider
      value={{
        shopPage,
        currencySymbol,
        checkout,
        cart,
        blockCart,
        cartProducts,
        totalCartQuantity,
        isUpdatingCart,
        processCart,
        addToCart,
        removeFromCart,
        updateCartQuantity,
        amount,
        discount,
        total,
        shippingCosts,
        shipping,
        paymentMethodId,
        setPaymentMethodId,
        shippingId,
        setShippingId,
        billingId,
        setBillingId,
        shippingMode,
        setShippingMode,

        isLoadingListings,
        listings,
        listingId,
        setListingId,
        getAvailableListings,
      }}
    >
      {children}
    </Provider>
  )
}
