import { useCallback } from 'react'

interface IUseUpdateCartQuantity {
  cart: any
  setCart: (cart: any) => void
}

export interface IUpdateCartQuantity {
  shopId: string
  productId: string
  quantity: number
}

const useUpdateCartQuantity = ({ cart, setCart }: IUseUpdateCartQuantity) => {
  const updateCartQuantity = useCallback(
    ({ shopId, productId, quantity }: IUpdateCartQuantity) => {
      const cartShop = (cart[shopId] = cart[shopId] || {})
      const shopProduct = (cartShop[productId] = cartShop[productId] || {})

      shopProduct.quantity = quantity
      setCart({ ...cart })
    },
    [cart, setCart]
  )

  return updateCartQuantity
}

export default useUpdateCartQuantity
