{
  "general.addToCart": "Add to cart",
  "general.total": "Total: ",
  "general.discount": "Discount: ",
  "general.listPrice": "List price: ",
  "general.price": "Price",
  "general.back": "Back",
  "general.firstName": "First name",
  "general.lastName": "Last name",
  "general.password": "Password",
  "general.close": "Close",
  "general.confirm": "Confirm",
  "general.cancel": "Cancel",
  "general.email": "Email",
  "shops.backToMainPage": "Back to shop main page",
  "shops.productCard.notAvailable": "Not available",
  "fields.phone": "Phone number",
  "fields.email": "Email",
  "fields.repeatEmail": "Repeat email",
  "fields.password": "Password",
  "fields.firstName": "First name",
  "fields.lastName": "Last name",
  "fields.company": "Company",
  "fields.street": "Street",
  "fields.number": "Street number",
  "fields.city": "City",
  "fields.province": "Province",
  "fields.state": "Region/State",
  "fields.country": "Country",
  "fields.zip": "ZIP",
  "fields.accountEmail": "Account email",
  "fields.cardName": "Card holder name",
  "fields.howToPay": "How would you like to pay?",
  "fields.howToPay.saved": "Saved payment method",
  "fields.howToPay.new": "New payment method",
  "fields.savePaymentMethod": "Save this payment method for later use",
  "fields.info": "Infos",
  "fields.person": "Client type",
  "fields.person.true": "Private",
  "fields.person.false": "Company",
  "fields.fiscalCompany": "Fiscal company name",
  "fields.code": "Company tax id",
  "fields.cf": "Italian fiscal code",
  "fields.pec": "Italian PEC email",
  "fields.sdi": "Italian SDI code",
  "home.view": "View",
  "navbar.welcome": "Welcome back",
  "profile.edit": "Edit your profile",
  "profile.info.title": "Personal data",
  "paymentMethod.title": "Payment data",
  "paymentMethod.preferred": "Set preferred method",
  "paymentMethod.addNew": "Add new credit card",
  "paymentMethod.manage": "Manage",
  "profile.orders.title": "Your orders",
  "shipping.title": "Shipping data",
  "shipping.selected": "Selected address",
  "shipping.addNew": "Add new shipping address",
  "shipping.manage": "Manage shipping addresses",
  "shipping.completeThisAddress": "Click here to complete this address",
  "billing.title": "Billing data",
  "billing.selected": "Selected",
  "billing.manage": "Manage billing data",
  "billing.addNew": "Add new billing data",
  "billing.completeThisBilling": "Click here to complete this data",
  "billing.invoice": "I want to receive an invoice intead of a receipt",
  "billing.invoiceMandatory": "Invoicing data is mandatory",
  "billing.invoiceAddress": "Invoice address",
  "billing.receiptAddress": "Billing address",
  "billing.invoiceData": "Invoice data",
  "confirmPassword.change": "Change you password",
  "confirmPassword.confirmPassword": "Confirm password",
  "changeEmail.title": "Change your email",
  "changeEmail.success": "Email change successful",
  "changeEmail.error": "Invalid email change token",
  "user.changeEmail": "Are you sure you want to change your email?",
  "user.changePassword": "Are you sure you want to change your password?",
  "user.resetPassword": "Reset password",
  "login.title": "Login",
  "login.notRegistered": "Not registered yet? ",
  "login.requestAccess": "Request access",
  "signup.registrationTitle": "Confirm registration",
  "signup.invitationTitle": "Request access",
  "signup.otherDataTitle": "Other data",
  "signup.privacy.service": "Accept service <pp>privacy policy</pp> and <tc>terms of service</tc>",
  "signup.privacy.accept": "Accept",
  "signup.alreadyRegistered": "Already registered? ",
  "signup.submit": "Confirm",
  "dialogs.profileChange.title": "Edit profile",
  "dialogs.profileChange.message": "Insert the new value for the selected field",
  "dialogs.profileChange.success": "Field updated successfully",
  "dialogs.emailChange.title": "Change email address",
  "dialogs.emailChange.message": "After you press confirm we will send you an email to the current address with further informations on how to proceed with the update to the new email address",
  "dialogs.emailChange.success": "Email sent successfully, you may close the popup",
  "dialogs.passwordReset.title": "Reset password",
  "dialogs.passwordReset.message": "Insert the email address of the account you wish to reset the password, we will send you an email with a link to proceed to the reset",
  "dialogs.passwordReset.success": "We've sent you an email with the instructions to proceed",
  "dialogs.addCard.title": "Insert credit card",
  "dialogs.addCard.message": "This card will be saved in your profile so you can use it to make purchases without having to insert it again",
  "dialogs.address.title": "Address",
  "dialogs.address.message": "Fill the address, all fields marked with * are mandatory",
  "cart.empty": "Your cart is empty",
  "cart.compact": "You have {quantity, plural, one {# product} other {# products}} in your cart, click here to see your cart and go to checkout",
  "cart.pricePerItem": "{currencySymbol} {price}",
  "cart.openCart": "Open cart and checkout",
  "cart.closeCart": "Close and continue shopping",
  "cart.addedToCart": "Added to cart!",
  "cart.subtotal": "Subtotal",
  "cart.discount": "Discount",
  "cart.shipping": "Shipping costs",
  "cart.total": "Total",
  "cart.goToCheckout": "Go to checkout",
  "cart.quantities": "You need to add products of type <b>{productType, select, wine {wine} liquid {liquid} food {food} generic {generic} other {}}</b> having a <b>{field, select, format {format} weight{weight} other {}}</b> of <b>{fieldValue, number, decimal}{field, select, format {l} weigth {kg} other {}}</b> by quantities multiple of <b>{quantity}</b> {check, plural, =0 {} other {(missing {missing})}}",
  "checkout.shipping.chooseShippingMode": "Choose shipping",
  "checkout.shipping.shipToBilling": "Ship to {formattedAddress}",
  "checkout.shipping.shipToAddress": "Ship to another address",
  "checkout.shipping.pickup": "I want to pick it up myself",
  "checkout.listings.title": "Choose shipping type",
  "checkout.listings.noShippingAvailable": "There are no shipping options that can serve the country you selected",
  "checkout.payment": "Payment",
  "checkout.newPayment": "Insert payment data",
  "checkout.savedPayment": "Choose an existing payment method",
  "checkout.confirmation.data": "Choose the payment method that should be used to fulfill this order",
  "checkout.completed.title": "Your order is complete!",
  "checkout.completed.message": "We'll start processing your order as soon as possible, an email has been sent to you just now with the data about your purchase. Also, this order is now listed on your profile.",
  "checkout.completed.goToOrders": "Check your orders",
  "checkout.completed.backToShop": "Back to shop",
  "checkout.openSummary": "Show order details",
  "checkout.hideSummary": "Hide order details",
  "checkout.steps.orderData": "Informations",
  "checkout.steps.shipping": "Shipping",
  "checkout.steps.payment": "Payment",
  "checkout.buttons.backToShop": "Back to shop",
  "checkout.buttons.backToOrderData": "Back to informations",
  "checkout.buttons.backToShipping": "Back to shipping",
  "checkout.buttons.goToShipping": "Go to shipping",
  "checkout.buttons.goToPayment": "Go to payment",
  "checkout.buttons.checkout": "Checkout",
  "checkout.discount": "Discount: {currencySymbol} {discount}",
  "checkout.contact": "Contact",
  "checkout.selectedListing": "Selected shipping",
  "sidebar.profile": "My profile",
  "sidebar.logout": "Logout",
  "authRequired.title": "Authentication required",
  "authRequired.message": "You are required to be logged into your account to proceed",
  "authRequired.goToLogin": "Login",
  "authRequired.goToSignup": "Signup",
  "UNAUTHORIZED": "Invalid email or password",
  "INVITATION_REQUIRED": "An invite is required to register",
  "ALREADY_INVITED": "An invite has already been sent to this email",
  "ALREADY_REGISTERED": "This email is already registered",
  "INVITATION_SENT": "We've sent you an email to the address specified with further instructions to complete the registration",
  "PASSWORD_TOO_SHORT": "The password must be at least 8 characters long",
  "EMAIL_CHANGE_CHOOSE_SUCCESS": "We've sent you an email to the specified address to confirm your chage",
  "EMAIL_CHANGE_CHOOSE_ERROR": "The token for this change is no longer valid",
  "INVITATION_TOKEN_INVALID": "This invitation link is invalid or expired, you should access from the latest invitation link you received"
}
